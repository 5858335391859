<script>
import Tooltip2Vue from '@/components/Tooltip2Vue/Tooltip2Vue.vue'

export default {
  components: {
    Tooltip2Vue
  }
}

</script>

<template>
  <Tooltip2Vue hover position="bottom-end">
    <div class="icon-exclamation">
      <i class="fas fa-exclamation"></i>
    </div>
    <template v-slot:tooltip>
      <div class="wrapper-tooltip">
        <slot>
          Este es un mensaje de advertencia
        </slot>
      </div>
    </template>
  </Tooltip2Vue>
</template>

<style scoped>
.icon-exclamation {
  border-radius: 50%;
  border: solid 1px rgb(129, 129, 129);
  text-align: center;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: silver;
}

.wrapper-tooltip {
  background-color: #141414;
  box-shadow: 0 0.5rem 5rem 2rem rgba(0, 0, 0, 1);
  padding: .5rem;
  text-align: start;
}
</style>