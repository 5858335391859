<script>

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    headers() {
      if (this.columns.length) {
        return this.columns
      }
      if (!this.data.length) {
        return []
      }
      return Object.keys(this.data[0]);
    },
    rows() {
      return this.data
    }
  },
}
</script>


<template>
  <div class="table-responsive height-limit">
    <table class="table table-bordered text-center">
      <thead>
        <tr>
          <th v-for="header in headers" :key="header">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) in rows" :key="row.id">
          <td v-for="(header, j) in headers" :key="`${i}-${j}-cell`">{{ row[header] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.height-limit {
  max-height: 300px;
  overflow-y: auto;
  margin: .5rem 0;
}

* {
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(255,255,255,.2);
}
</style>