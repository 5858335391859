<script>
import TooltipExclamation from '@/components/assistant/TooltipExclamation.vue'
import ObjectTable from '@/components/ObjectTable/ObjectTable.vue'
import StarBtn from '@/components/buttons/StartBtn.vue'

export default {
  components: {
    TooltipExclamation,
    ObjectTable,
    StarBtn
  },
  props: {
    historyMessages: {
      type: Array,
      default: () => []
    },
    value: {
      // userMessage
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Chatbot'
    },
    waitingMessage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userMessage: '',
      indexHistoryTool: 0,
      userMessageBackup: '',
      showMore: false,
      favoritosIndices: []
    }
  },
  computed: {
    showMessage() {
      return this.historyMessages.filter(message => message.role !== 'system')
    },
    userMessages() {
      return this.historyMessages.filter(message => message.role === 'user')
    },
    empresaId() {
      return this.$store.getters.userLoggedIn.empresa.id
    }
  },
  methods: {
    handleKeyup(ev) {
      this.$emit('keyup', ev )

      if( ev.key === 'Enter' ) {
        this.scrollSmoothHistory()
      }

      
      // const auxIndex = this.indexHistoryTool

      // if (ev.key === 'Enter') {
      //   this.sendMessage()
      // } else if (ev.key === 'ArrowUp') {
      //   this.indexHistoryTool += this.indexHistoryTool < this.userMessages.length ? 1 : 0
      //   // guarda el mensaje actual en un backup. si cambia de 0


      // } else if (ev.key === 'ArrowDown') {
      //   this.indexHistoryTool += this.indexHistoryTool > 0 ? -1 : 0

      // } else if (ev.key === 'Escape') {
      //   this.indexHistoryTool = 0
      // }

      // if (auxIndex === 0 && this.indexHistoryTool > 0) {
      //   this.userMessageBackup = this.userMessage
      // } else if (this.indexHistoryTool === 0 && auxIndex > 0) {
      //   this.userMessage = this.userMessageBackup
      // } else {
      //   this.userMessage = this.userMessages[this.userMessages.length - this.indexHistoryTool].content
      // }

    },
    scrollSmoothHistory() {
      const chat = this.$el.querySelector('#history')
      this.$nextTick(() => {
        chat.scrollTo({
          top: chat.scrollHeight,
          behavior: 'smooth'
        });
      })
    },
    toggleIndiceFavorito( indice, toBackend = true ) {
      let newState 

      if( this.favoritosIndices.includes( indice ) ) {
        this.favoritosIndices = this.favoritosIndices.filter( i => i !== indice )
        newState = false
      } else {
        this.favoritosIndices.push( indice )
        newState = true
      }

      if( toBackend ) {
        this.setFavoriteMessage({
          isFavorite: newState,
          assistantMessage: this.showMessage[indice].content,
          indice: indice
        })
      }
    },
    async setFavoriteMessage( bodyData ) {
      const response = await this.$store.getters.fetchPut({ path: 'ChatAssistantAI/SetIsFavorite', data: bodyData })
      if( !response.ok ) {
        this.toggleIndiceFavorito( bodyData.indice, false )
      }
    }
  }
}


</script>

<template>
  <section class="chatbot">
    <div class="header-chatbot">
      <label for="userMessage" class="title">{{ title }}</label>
      <TooltipExclamation>
        <slot>
          <p>Estoy preparado para responder preguntas específicas sobre los resultados de evaluación.</p>
          <p>Para un mejor rendimiento, te sugiero acotar la pregunta a una cantidad.</p>
        </slot>
      </TooltipExclamation>
    </div>
    <ul class="messages history" id="history">
      <li v-for="({ role, content, resultQuery }, index) in showMessage" :key="content" class="message"
        :class="role == 'user' ? 'm-user' : 'm-assistant'">

        <div class="message-text">
          <header class="my-1">
          
            <StarBtn  
            v-if="role == 'assistant'" 
            title="Guardar en favoritos"
            :value="favoritosIndices.includes(index)" 
            @input="toggleIndiceFavorito(index)"/>

          </header>

          <article v-if="content.includes('<article>')" v-html="content" ></article>
          <p v-else >
            {{ content }}
          </p>

          <div v-if="role == 'assistant' && resultQuery && resultQuery.length">
            <vs-button class="m-0 my-3" gradient size="small" @click="showMore = !showMore">{{ showMore ? 'Ocultar' : 'Mostrar más' }}</vs-button>
            <ObjectTable :data="resultQuery" v-if="showMore" />
          </div>

        </div>
      </li>
      <li v-if="waitingMessage" class="message m-assistant">
        <p class="message-text">
          <i class="fas fa-spinner fa-spin"></i>
        </p>
      </li>
    </ul>
    <input 
    placeholder="Ingresa tu mensaje..." 
    class="input"
    @keyup="handleKeyup" 
    type="text" id="userMessage"
    :value="value"
    @input="$emit('input', $event.target.value)">
  </section>
</template>

<style scoped>
.chatbot {
  background-color: #050505;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-chatbot {
  margin-bottom: 1rem;
}

.chatbot .title {
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.messages {
  flex: 1;
  overflow: auto;
  padding: 0;
}

.message {
  list-style: none;
  margin: .25rem 0;
  padding: .5rem .25rem;

}


.m-user {
  display: flex;
  flex-direction: row-reverse;

}

.m-assistant {
  display: flex;
  flex-direction: row;
}

.message-text {
  padding: .25rem 1rem;
  border-radius: 0.5rem;
}

.m-user .message-text {
  border: solid 1px rgb(129, 129, 129);
  max-width: 95%;
}

.m-assistant .message-text {
  background-color: #1c1c1c;
  border: solid 1px #050505;
  max-width: 95%;
}

.input {
  border: solid 1px #050505;
  padding: .5rem;
  margin-top: 1rem;
  border-radius: .5rem;
  font-size: 1rem;
  background-color: #1c1c1c;
  color: #fff;
  margin-bottom: .5rem;
}

.header-chatbot {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
</style>
